<template>
  <div class="surface-section px-4 pb-8 md:px-6 lg:px-8 text-center flex flex-column align-items-center">
    <div class="banner-image">
        <img style="width: 100%;" alt="pago cancelado" :src="publicPath + 'images/payment/payment_cancelled.png'" />
    </div>
    <p class="text-2xl red-text">¡El pago ha sido cancelado!</p>
    <p class="text-lg">
      No dude en contactarnos en caso de tener algún problema o duda respecto al servicio. <br> Gracias por confiar
      en nosotros.
    </p>
    <router-link to="/"><i class="pi pi-fw pi-home"></i> Ir al Dashboard</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
};
</script>

<style lang="scss" scoped>

.red-text {
  color: #FF0000;
}

.banner-image {
    width: min(80vw, 640px );
}
</style>
